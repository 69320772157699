<template>
  <card :title="$t('series.info')">
    <form @submit.prevent="update" @keydown="form.onKeydown($event)">
      <alert-success :form="form" :message="$t('series.updated')" />

      <series-form :form="form"></series-form>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <v-button :loading="form.busy" type="success">{{ $t('update') }}</v-button>
        </div>
      </div>
    </form>
  </card>
</template>

<script>
import Form from "vform"
import SeriesForm from "./form"
import { mapGetters } from "vuex"
import { objectToFormData } from 'object-to-formdata'

export default {
  scrollToTop: false,
  components: {
    "series-form": SeriesForm
  },
  data: () => ({
    form: new Form({
      thumbnailUrl: "",
      thumbnail: null,
      title: "",
      description: "",
    })
  }),
  created() {
    const series = this.series[this.$route.params.id];

    if (series) {
      this.refresh(series);
    } else {
      this.$store.dispatch('account/fetchSeries');
    }
  },
  computed: {
    ...mapGetters({
      series: "account/series"
    })
  },
  methods: {
    refresh(series) {
      this.form.keys().forEach(key => {
        this.form[key] = series[key];
      });
    },
    async update() {
      const { data } = await this.form.submit('post','/api/v1/user/me/series/' + this.$route.params.id, {
        // Transform form data to FormData
        transformRequest: [function(data) {
          data._method = "put";
          return objectToFormData(data);
        }]
      });
      
      const series = data.series;

      // Update the store
      this.$store.dispatch("account/updateSeries", { series: series });

      // Update the form
      this.refresh(series);
    }
  }
};
</script>
